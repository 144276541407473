import { CircularProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import RequireAuth from "./components/RequireAuth";
import SnackBar from "./components/SnackBar";
import Unauthorized from "./components/Unauthorized";
import SharedLayout from "./layouts/SharedLayout";
import Admins from "./pages/Admins";
import ClearRequests from "./pages/ClearRequests";
import Login from "./pages/Login";
import Logs from "./pages/Logs";
import LogsRefferals from "./pages/LogsRefferals";
import Notifications from "./pages/Notifications";
import Operations from "./pages/Operations";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Statistics from "./pages/Statistics";
import Transactions from "./pages/Transactions";
import Users from "./pages/Users";
import { authorize } from "./store/slices/authSlice";
import UserDetails from "./pages/UserDetails";
import LogsRefferals2 from "./pages/LogsRefferals2";

function App() {
  const [loading, setLoading] = React.useState(true);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // let navigate = useNavigate();

  React.useEffect(() => {
    /**
     * Check authorize
     */
    if (user == null) {
      let userToken = localStorage.getItem("token");
      console.log("userToken", userToken);

      if (userToken) {
        dispatch(authorize(userToken))
          .then(unwrapResult)
          .then((result) => {
            console.log("result", result); // => 233
            setLoading(false);
            axios.defaults.headers.common["Authorization"] = userToken;

            // navigate.push('/dashboard')
            // navigate("/dashboard", {replace: true});
          })
          .catch((error) => {
            navigate("/login", { replace: true });
            setLoading(false);
            console.error("error", error); // if there is an error
          });
      } else {
        setLoading(false);
        // return <Navigate replace to="/login"/>
        navigate("/login", { replace: true });
      }
    } else {
      setLoading(false);
      // return <Navigate replace to="/login"/>
    }
  }, []);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div className="App">
        <SnackBar />
        <Routes>
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="login" element={<Login />} />

          <Route path="/" element={<SharedLayout />}>
            <Route path="/transactions" element={<Transactions />} />
            <Route path="users" element={<Users />} />
            <Route path="/user/:userId" element={<UserDetails />} />

            <Route element={<RequireAuth allowedRoles={["admin"]} />}>
              <Route path="admins" element={<Admins />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={["admin"]} />}>
              <Route path="settings" element={<Settings />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={["admin"]} />}>
              <Route path="operations" element={<Operations />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={["admin"]} />}>
              <Route path="statistics" element={<Statistics />} />
            </Route>

            <Route path="profile" element={<Profile />} />
            <Route path="requests" element={<ClearRequests />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="history" element={<Logs />} />
            <Route path="history_refferals" element={<LogsRefferals2 />} />

            <Route path="/" element={<Navigate replace to="/users" />} />
          </Route>
        </Routes>
      </div>
    );
  }
}

export default App;
